.content-browser {
	display: flex;
	flex-direction: column;
	position: absolute;
	max-width: 23%;
	min-width: 322px;
	max-height: 90%;
	top: 5%;
	left: 0;
	z-index: $z-Index-highest;
	margin-left: 30px;
	opacity: 0;
	transform: translateX(-1000px);
	pointer-events: none;
	font-family: $font-family;
	text-shadow: $color-black 0 3px 1px;

	&.show {
		opacity: 1;
		transition: all 0.5s ease-in-out;
		transform: translateX(0);
	}

	&.isLeva {
		position: relative;
		width: auto;
		height: auto;
		max-height: 70vh;
		max-width: none;
	}

	.top-bar {
		position: relative;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;
		margin-right: calc(4% + 5px);

		.top-bar-search {
			border-radius: 4px;
			border: 1px solid $color-bg-secondary;
			text-align: center;
			margin: 0;
			text-transform: uppercase;
			width: 100%;
			font-family: $font-family;
			font-size: 22px;
			padding: 10px 0 5px;
			color: $color-grey;
		}

		.search-icon {
			padding-left: 4px;
		}
	}

	.content-container {
		height: 100%;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		min-height: 0;
		pointer-events: none;

		.side-bar {
			border-radius: 8px;
			position: relative;
			display: flex;
			flex-direction: column;
			margin-top: 76px;
			pointer-events: none;

			.tab {
				position: relative;
				display: block;
				border-radius: 14px 0 0 14px;
				cursor: pointer;
				padding: 10px 4px;
				background: $color-bg-secondary-dark;
				border-color: rgba($color-bg-secondary, 0.4);
				border-style: solid;
				border-width: 1px 0 1px 1px;
				pointer-events: auto;

				.tab-name {
					color: $color-white;
					text-align: center;
					margin-bottom: 8px;
				}

				.tab-icon {
					filter: invert(1);
					width: 40px;
					margin: auto;
					display: block;
					padding: 3px;
				}

				&.active {
					background: $color-bg-primary;
				}
			}
		}

		.content-container-main {
			display: flex;
			flex-direction: column;
			background: $color-bg-primary;
			padding: 15px 5px 5px 20px;
			margin-bottom: -15px;
			border-radius: 16px;
			border-color: rgba($color-bg-secondary, 0.4);
			border-style: solid;
			border-width: 1px 1px 1px 0;
			pointer-events: auto;
		}

		.content {
			height: 100%;
			width: 100%;
			display: none;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			overflow: auto;

			.item {
				margin: 6px 4% 0 0;
				width: calc(50% - 4%);

				@include breakpoint($bp-maxlarge) {
					width: calc(33% - 4%);
				}
			}

			&.active {
				display: flex;
			}

			// &.--models {
			// 	.item {
			// 		img {
			// 			filter: none;
			// 			pointer-events: none;
			// 		}
			// 	}
			// }
		}

		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: grab;
			color: $color-white;

			h4 {
				pointer-events: none;
				text-align: center;
				flex-grow: 1;
				margin: 0;
				margin-top: 4px;
			}

			#thumbnail-container {
				display: inline-block;
				position: relative;
				width: 100%;
				background: $color-bg-secondary;
				border: 2px solid $color-bg-primary;
				border-radius: 8px;
			}

			#dummy {
				margin-top: 100%;
				/* 1:1 aspect ratio */
			}

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				margin: auto;
				padding: 15px;
				// filter: invert(1);
				pointer-events: none;
			}
		}
	}

	.setting-button {
		position: absolute;
		background: $transparent;
		border: none;
		height: 50px;
		width: 50px;
		right: 0;
		top: 0;
		transform: translate(60px, 20px);
		pointer-events: all;
		cursor: pointer;

		img {
			width: 100%;
		}
	}
}
