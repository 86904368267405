.object-data {
	display: flex;
	flex-direction: column;
	position: absolute;
	max-width: 20%;
	min-width: 250px;
	max-height: 90%;
	top: 5%;
	left: auto;
	right: 0;
	z-index: $z-Index-highest;
	background: $color-bg-primary;
	margin-right: 60px;
	padding: 20px;
	border-radius: 16px;
	opacity: 0;
	transform: translateX(1000px);
	font-family: $font-family;
	text-shadow: $color-black 0 4px 0;
	color: $color-white;
	letter-spacing: 1.3px;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

	&.show {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	}

	.object-data-container {
		height: 100%;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;

		.profile-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-bottom: 14px;

			.profile-pic {
				width: 38px;
				height: 38px;
				margin-right: 14px;
			}

			.username {
				font-size: 24px;
				margin: 0;
				margin-top: 9px;
			}
		}

		#divider {
			border-top: 1px solid $color-white;
			width: 100%;
			margin-bottom: 15px;
		}

		.transformSelected-data {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
			opacity: 0;

			&.show {
				opacity: 1;
				max-height: 1500px;
				transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
			}
		}

		.heading {
			font-size: 20px;
		}

		.data {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;

			.row {
				display: flex;
				flex-direction: row;
				padding-bottom: 20px;

				&.align-options {
					padding-top: 5px;
				}
			}

			.label {
				font-size: 32px;
				display: block;
				height: auto;
				margin: 0;
				padding: 0;
			}

			.variable {
				display: block;
				border-radius: 0;
				border: none;
				padding: 0;
				padding-top: 5px;
				padding-left: 5px;
				margin: 0;
				margin-left: 12px;
				font-family: $font-family;
				font-size: 24px;
				color: $color-black;
				width: 170px;
				box-shadow: 0 1px 0 1px $color-black;
			}

			.variable:focus {
				outline: 0;
			}

			.anchor-icon {
				display: block;
				padding: 0 6px;
				cursor: pointer;
			}

			.toggle {
				position: relative;
				width: 74px;
				height: 36px;
				overflow: hidden;
				border-radius: 100px;

				.checkbox {
					position: relative;
					width: 100%;
					height: 100%;
					padding: 0;
					margin: 0;
					opacity: 0;
					cursor: pointer;
					z-index: 3;
				}

				.knobs {
					z-index: 2;
				}

				.layer {
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(107, 157, 179, 0.6);
					transition: 0.3s ease all;
					z-index: -1;
				}

				.knobs:before {
					content: 'Off';
					position: absolute;
					top: 4px;
					left: 42px;
					width: 20px;
					height: 10px;
					color: $color-white;
					text-shadow: $color-black 0 3px 0;
					font-size: 10px;
					font-weight: bold;
					text-align: center;
					line-height: 1;
					padding: 9px 4px;
					background-color: #039ae0;
					border-radius: 50%;
					transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
				}

				.checkbox:checked + .knobs:before {
					content: 'On';
					left: 4px;
					background-color: #03a9f4;
				}

				.checkbox:checked ~ .layer {
					background-color: #e9f6fc;
				}

				.knobs,
				.knobs:before,
				.layer {
					transition: 0.3s ease all;
				}
			}
		}

		.delete-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			background: $transparent;
			border: none;
			padding: 0;
			cursor: pointer;

			.delete {
				font-size: 20px;
				font-family: $font-family;
				color: $color-red;
				letter-spacing: 1.3px;
				text-shadow: $color-black 0 3px 0;
			}
		}

		.exit-container {
			width: 100%;
			margin-top: 60px;
			display: flex;
			justify-content: center;

			.exit-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				background: $transparent;
				border: none;
				cursor: pointer;
			}

			.exit {
				font-size: 24px;
				margin: 0;
				padding-top: 5px;
				text-shadow: none;
				font-family: $font-family;
				color: $color-white;
				letter-spacing: 1.3px;
			}

			.exit-arrow {
				width: 50px;
				margin-left: 6px;
			}
		}
	}
}
