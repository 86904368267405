.save-project-bg {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #00000099;
	z-index: $z-Index-prompt;
	opacity: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	&.hide-prompt {
		transition: opacity 0.4s ease-in-out;
		opacity: 0;
		pointer-events: none;

		.save-project {
			transition: transform 0.5s ease-in-out;
			transform: translateY(-400px);
		}
	}
}

.save-project {
	position: relative;
	width: 40%;
	border-radius: 16px;
	background: $color-bg-atlas-blue;
	color: $color-white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 20px 5%;
	font-family: $font-family;
	text-shadow: $color-black 0 3px 1px;

	.text {
		font-size: 22px;
		line-height: 30px;
		padding-bottom: 30px;

		&.note {
			font-size: 18px;
			padding-bottom: 10px;
		}
	}

	.save-project-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-bottom: 30px;

		input:focus {
			outline: none;
		}

		label {
			margin-bottom: 10px;
			text-align: left;
		}

		.angled-input-div {
			display: flex;
			width: 100%;

			.angled-input {
				border: none;
				height: 30px;
				display: block;
				width: 100%;

				text-transform: uppercase;
				font-family: $font-family;
				font-size: 20px;
				padding: 10px 15px 5px;
				color: $color-grey;
				border-radius: 4px;
			}

			.add-angle {
				display: block;
				margin-left: -2px;
				border-right: 25px solid transparent;
				border-bottom: 30px solid #ffffff;
				border-radius: 4px 0 4px 0;
			}
		}
	}

	.button-container {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.button {
		font-family: $font-family;
		color: $color-white;
		background-color: $color-black;
		border: 1px solid transparent;
		border-radius: 8px;
		border-width: 0 0 4px;
		box-sizing: border-box;
		cursor: pointer;
		display: inline-block;
		font-size: 34px;
		font-weight: 400;
		outline: none;
		overflow: visible;
		text-align: center;
		text-transform: uppercase;
		touch-action: manipulation;
		transform: translateZ(0);
		transition: filter 0.2s;
		user-select: none;
		-webkit-user-select: none;
		vertical-align: middle;
		white-space: nowrap;
		width: 100%;
		margin: 20px;
		padding: 20px 12px 12px 12px;
	}

	.button:after {
		background-clip: padding-box;
		background-color: #1cb0f6;
		border: solid transparent;
		border-radius: 10px;
		border-width: 0 0 4px;
		bottom: -4px;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}

	.button:main,
	.button:focus {
		user-select: auto;
	}

	.button:hover:not(:disabled) {
		filter: brightness(1.2);
		-webkit-filter: brightness(1.2);
	}

	.button:disabled {
		cursor: auto;
	}

	.cancel:after {
		background-color: #fe5e22;
	}

	.ready:after {
		background-color: #00d26f;
	}

	.arrow {
		border: solid $color-black;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.up {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
	}

	.down {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
}
