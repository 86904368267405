$transparent: transparent;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #8d8f8d;
$color-off-white: #bcbcbc;
$color-red: red;

$color-bg-primary: #0060b7;
$color-bg-secondary: #004681;
$color-bg-secondary-dark: #003241;

$color-bg-atlas-blue-solid: #004c93;
$color-bg-atlas-blue: #0060b7c7;
