* {
	box-sizing: border-box;
}

html,
body,
.App,
#root {
	height: 100%;
	width: 100%;
	outline: none;
	background: $color-black;
	overflow: hidden;
}

.bg-canvas {
	height: 100%;
	width: 100%;

	canvas {
		height: 100%;
		width: 100%;
		outline: none;
		z-index: $z-Index-canvas;
	}
}

/* ===== Scrollbar CSS ===== */
*::-webkit-scrollbar {
	width: 18px;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: rgba($color-bg-secondary, 0.3);
	border: 2px solid rgba($color-bg-secondary, 0.6);
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	border: 5px solid transparent;
	background-clip: content-box;
	background-color: rgba($color-bg-secondary, 0.8);
}
