@import '1-settings/fonts';
@import '1-settings/colors';
@import '1-settings/breakpoints';
@import '2-tools/breakpoint-sass';
@import '2-tools/variables';
@import '3-base/misc';
@import '4-components/content-browser';
@import '4-components/home';
@import '4-components/object-data';
@import '4-components/parcel-prompt';
@import '4-components/save-project';

.stats {
	left: auto !important;
	right: 0 !important;
	top: auto !important;
	bottom: 0 !important;
}

.gl-stats {
	left: auto !important;
	right: 270px !important;
	top: auto !important;
	bottom: 48px !important;
}

#leva__root > * {
  top: auto;
	bottom: 10px;
}

.scene-objects-helper {
	position: relative;
	// height: 684px;
	// width: 402px;
	border-radius: 16px;
	background: $color-bg-atlas-blue;
	color: $color-white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 6px 20px;
	// 	left: auto !important;
	// right: 270px !important;
	// top: auto !important;
	// bottom: 48px !important;

	.button {
		font-family: $font-family;
		color: $color-white;
		background-color: $color-black;
		border: 1px solid transparent;
		border-radius: 8px;
		border-width: 0 0 4px;
		box-sizing: border-box;
		cursor: pointer;
		// font-size: 34px;
		font-weight: 400;
		outline: none;
		overflow: visible;
		text-align: center;
		text-transform: uppercase;
		touch-action: manipulation;
		transform: translateZ(0);
		transition: filter 0.2s;
		user-select: none;
		-webkit-user-select: none;
		vertical-align: middle;
		white-space: nowrap;
		width: 100%;
		margin: 5px;
		padding: 10px 10px 2px 10px;
		display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
	}

	.button:after {
		background-clip: padding-box;
		background-color: #1cb0f6;
		border: solid transparent;
		border-radius: 10px;
		border-width: 0 0 4px;
		bottom: -4px;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}

	.button.navigate:after {
		background-color: #f6531c;
	}

	.button:main,
	.button:focus {
		user-select: auto;
	}

	.button:hover:not(:disabled) {
		filter: brightness(1.2);
		-webkit-filter: brightness(1.2);
	}

	.text {
		margin: 5px;
	}

	.arrow {
		border: solid $color-white;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.up {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
	}

	.down {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
}
